import React, { Fragment, useContext, useState } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import imageBanner from "../../assets/note.png";
import { BannerSuport } from "./bannerSuport";
import { BannerInfoSuport } from "./bannerInfoSuport";
import "./helpdesk.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import axios from "axios";

const HelpdeskForm = () => {
  const { actualLang } = useContext(ActualLangContext);
  const isInSpanish = actualLang === "es";
  const [values, setValues] = useState({
    yourname: "",
    email: "",
    message: "",
    position: "",
    file: "",
    fileName: "",
    sucursal: "",
    asunto: "",
  });

  const [postSendMessage, setPostSendMessage] = useState({
    title: "",
    message: "",
    success: false,
    active: false,
  });
  const { yourname, email, message, fileName, position, sucursal, asunto } =
    values;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = () => {
        reject(new Error("Error al leer el archivo"));
      };

      reader.readAsDataURL(file);
    });
  };

  function handleInputFileChange(e) {
    const file = e.target.files[0];
    convertBase64(file).then((base64) => {
      setValues({
        ...values,
        file: base64,
        fileName: file.name ?? "",
      });
    });
  }

  function handleInputChange(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }

  async function sendData(token) {
    await axios
      .post(
        "https://prod-02.brazilsouth.logic.azure.com:443/workflows/3a152407bd79478a901bf72d42d781f2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bcE56NNDD7sR1a6HnmG6mOVM_EGfUqvnmf8osFssn_A",
        { ...values, response: token }
      )
      .then((res) => {
        setPostSendMessage({
          title: "Se ha enviado el ticket correctamente",
          message: "Nos pondremos en contacto en breve.",
          success: true,
          active: true,
        });
      })
      .catch((err) => {
        setPostSendMessage({
          title: "Ocurrió un error al enviar el ticket",
          message:
            "Por favor intentlo nuevamente mas tarde ó comuniquese a support@possumus.tech.",
          success: true,
          active: true,
        });
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
          action: "submit",
        })
        .then(function (token) {
          sendData(token);
        });
    });
  };

  return (
    <Fragment>
      <div className="style-container-heldesk">
        <BannerSuport imageBanner={imageBanner} />
        <BannerInfoSuport />
        <Container id="getInTouch">
          <Row>
            <Col className="getInTouchForm">
              {postSendMessage.active ? (
                <Col
                  className={
                    postSendMessage.success
                      ? "message error"
                      : "message success"
                  }
                >
                  <h2>{postSendMessage.title}</h2>
                  <p>{postSendMessage.message}</p>
                </Col>
              ) : (
                <>
                  <h2 className="text-left title">
                    {isInSpanish ? "Detalles del ticket" : "Ticket Details"}
                  </h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Col} className="input-getInTouch">
                      <Form.Control
                        id="yourname"
                        name="yourname"
                        value={yourname}
                        onChange={handleInputChange}
                        required
                        placeholder={
                          isInSpanish ? "Nombre completo*" : "Full name*"
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="input-getInTouch">
                      <Form.Control
                        required
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        onChange={handleInputChange}
                        placeholder="Email *"
                      />
                    </Form.Group>
                    <Row className="px-0">
                      <Col lg={4} className="px-0 pr-lg-4">
                        <Form.Group className="input-getInTouch">
                          <Form.Select
                            required
                            name="asunto"
                            id="asunto"
                            type="asunto"
                            value={asunto}
                            onChange={handleInputChange}
                            placeholder="Prioridad del pedido *"
                          >
                            <option value="" disabled hidden selected>
                              {isInSpanish
                                ? "Prioridad del pedido *"
                                : "Ticket priority *"}
                            </option>
                            <option value="baja">Baja</option>
                            <option value="media">Media</option>
                            <option value="alta">Alta</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="px-0 px-lg-4">
                        <Form.Group className="input-getInTouch">
                          <Form.Select
                            required
                            name="sucursal"
                            id="sucursal"
                            type="sucursal"
                            value={sucursal}
                            onChange={handleInputChange}
                            placeholder="Sucursal *"
                          >
                            <option value="" disabled hidden selected>
                              {isInSpanish ? "Sucursal *" : "Workplace *"}
                            </option>
                            <option value="San Rafael">San Rafael</option>
                            <option value="Mendoza">Mendoza</option>
                            <option value="General Alvear">
                              General Alvear
                            </option>
                            <option value="Otro">Otro</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="px-0 pl-lg-4">
                        <Form.Group className="input-getInTouch">
                          <Form.Select
                            required
                            name="position"
                            id="position"
                            type="position"
                            value={position}
                            onChange={handleInputChange}
                            placeholder="Posición de trabajo *"
                          >
                            <option value="" disabled hidden selected>
                              {isInSpanish
                                ? "Posición de trabajo *"
                                : "Job position *"}
                            </option>
                            <option value="Business Unit Manager">
                              Business Unit Manager
                            </option>
                            <option value="Business Applications Consultant">
                              Business Applications Consultant
                            </option>
                            <option value="Chief software Arquitect">
                              Chief software Arquitect
                            </option>
                            <option value="ITaaS Level 1 Consultant">
                              ITaaS Level 1 Consultant
                            </option>
                            <option value="Product Owner">Product Owner</option>
                            <option value="QA Analyst">QA Analyst</option>
                            <option value="SRE Consultant">
                              SRE Consultant
                            </option>
                            <option value="Software Engineer">
                              Software Engineer
                            </option>
                            <option value="Designer UX">Designer UX</option>
                            <option value="Software Tech Lead">
                              Software Tech Lead
                            </option>
                            <option value="Trainer">Trainer</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group as={Col} className="input-getInTouch">
                      <Form.Control
                        name="file"
                        id="file"
                        type="file"
                        fileName={fileName}
                        onChange={handleInputFileChange}
                        placeholder="Adjuntar archivo"
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="input-getInTouch">
                      <Form.Control
                        className="ps-0 pe-0"
                        id="message"
                        name="message"
                        as="textarea"
                        value={message}
                        onChange={handleInputChange}
                        required
                        rows={5}
                        placeholder={isInSpanish ? "Incidencia *" : "Message *"}
                      />
                      <Form.Label htmlFor="message">
                        {isInSpanish
                          ? "Breve descripción del inconveniente o incidencia"
                          : "Brief description of the problem or incident"}
                      </Form.Label>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      value="Submit"
                      disabled={postSendMessage.active}
                    >
                      {isInSpanish ? "Enviar" : "Send"}
                    </Button>
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default HelpdeskForm;
