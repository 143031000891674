export const valueProposalEs = {
  valueProposal: {
    title: "Propuesta de valor",
    description1: `Proporcionamos servicios que abordan este vacío crítico, permitiendo que las empresas que no son expertas en tecnología naveguen con confianza la complejidad del panorama tecnológico y digital, innoven sin restricciones y se mantengan competitivas, asegurando así su relevancia y prosperidad en un mercado que no perdona a los que no se adaptan.`,
    description2: `
    Co creamos con nuestros clientes productos de software de alta calidad, para abordar la transformación digital, siempre haciendo foco en la usabilidad y el diseño.`,
    button: "Más sobre de nosotros",
  },
};

export const valueProposalEn = {
  valueProposal: {
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
    button: "{{button}}",
  },
};
