export const aboutUsEs = {
  about: {
    aboutMenuHeader:"Nosotros",
    titleLinkMenu: "Sobre nosotros",
    title: "Sobre nosotros",
    subTitle: "¿Qué nos mueve?",
    description1:
      "Ayudamos a las empresas a transformar sus ideas en productos de software efectivos y a diseñar, operar y escalar sus ecosistemas digitales, usando las mejores prácticas del desarrollo de software, la ingeniería de plataforma y la transformación digital, asegurando que innoven, sean competitivas y relevantes en el mercado.",
    whatWeAre: "Quienes somos",
  },
  bannerAboutUs: {
    title: "Qué nos mueve",
    description1: "Creemos que la digitalización ayuda a la evolución del mundo, e integra a personas y empresas, facilitando su desarrollo.",
    description2: "En todo lo que hacemos, apostamos por la innovación y lo hacemos mediante una cuidadosa integración de recursos humanos y tecnología.",
  },
  valueProposalAboutUs: {
    toptitle: "Sobre nosotros",
    title: "Propuesta de valor",
    description1: "Proporcionamos servicios que abordan este vacío crítico, permitiendo que las empresas que no son expertas en tecnología naveguen con confianza la complejidad del panorama tecnológico y digital, innoven sin restricciones y se mantengan competitivas, asegurando así su relevancia y prosperidad en un mercado que no perdona a los que no se adaptan.",
  },
  ourWorkAboutUs: {
    title: "Nuestro Trabajo",
    description1: "Desarrollamos productos y servicios digitales, enfocados en la mejor experiencia de los usuarios.",
  },
};

export const aboutUsEn = {
  about: {
    aboutMenuHeader:"{{aboutMenuHeader}}",
    titleLinkMenu:"{{titleLinkMenu}}",
    title: "{{title}}",
    subTitle: "{{subTitle}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
    whatWeAre: "{{whatWeAre}}",
  },
  bannerAboutUs: {
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
  },
  valueProposalAboutUs: {
    toptitle: "{{toptitle}}",
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
  },
  ourWorkAboutUs: {
    title: "{{title}}",
    description1: "{{description1}}",
  },
};
