import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEOContent = ({ SEOData, children }) => {
  const { title, description, url, keywords, image } = SEOData;
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta property="og:keywords" content={keywords} />
        <meta property="og:image" content={image?.url} />
        {children}
      </Helmet>
    </HelmetProvider>
  );
};

export default SEOContent;
