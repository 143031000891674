import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imgWhats from "../../assets/whatsMoves.svg";
import imgValuesProposal from "../../assets/valueProposal.svg";
import { useTranslation } from "react-i18next";
import { aboutUsWhatMovesUs, valueProposal } from "../../config/home/textPage";
import "../../css/what.css";

const WhatMoveUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center d-flex">
          <Col lg={3} className="marginLeft50">
            <img
              className="widthMoveUS"
              src={imgWhats}
              alt="What moves us"
            ></img>
          </Col>
          <Col lg={6}>
            <p className="sup-title text-uppercase d-none d-sm-none d-md-block ">
              {t("about.title", {
                title: `${aboutUsWhatMovesUs.title}`,
              })}
            </p>
            <h4 className="text-left marginMobile50 titleStaffCentered">
              {" "}
              {t("about.subTitle", {
                subTitle: `${aboutUsWhatMovesUs.subTitle}`,
              })}
            </h4>
            <p className="padding10">
              {t("about.description1", {
                description1: `${aboutUsWhatMovesUs.description1}`,
              })}
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="lineCenter"></Row>
        <Row className="justify-content-center align-items-center d-flex">
          <Col lg={6} className="align-self-center">
            <h4 className="text-left titleStaffCentered">
              {t("valueProposal.title", {
                title: `${valueProposal.title}`,
              })}
            </h4>
            <p className="padding10">
              {t("valueProposal.description1", {
                description1: `${valueProposal.description1}`,
              })}
            </p>
          </Col>
          <Col lg={3} className="marginLeft50">
            <img
              className="widthMoveUS"
              src={imgValuesProposal}
              alt="Value Proposal"
            ></img>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhatMoveUs;
