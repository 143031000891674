import React, { Fragment } from "react";
import HelpdeskForm from "../components/helpdesk/helpdeskForm.jsx";
import { Footer } from "../components/commons/footer";
import MenuUsa from "../components/commons/menuUsa";

export const Helpdesk = () => {
  return (
    <Fragment>
      <MenuUsa />
      <HelpdeskForm />
      <Footer />
    </Fragment>
  );
};

export default Helpdesk;
