import React, { useContext } from "react";
import { Button, Col, Row, Image } from "react-bootstrap";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const GetInTouchPersonal = ({ personal }) => {
  const { actualLang } = useContext(ActualLangContext);
  const isInEnglish = actualLang === "en";
  const isMoreThanOne = personal.length > 1;
  return (
    <>
      <Col
        className="getInTouchPersonal"
        style={{ alignItems: isMoreThanOne ? "initial" : "center" }}
      >
        <Row>
          {personal.map((persona, idx) => (
            <Col
              sm={6}
              key={idx}
              className="personal d-flex flex-column justify-content-center align-items-center"
            >
              <Image
                src={persona.Image_personal?.url}
                alt={persona.nombre_apellido}
              />
              <h2>
                {persona.nombre_apellido.split(" ").map((nombre) => (
                  <>
                    {nombre}
                    <br />
                  </>
                ))}
              </h2>
              <h3>{persona.puesto}</h3>
            </Col>
          ))}
        </Row>
        <Button
          variant="secondary"
          href={isInEnglish ? "tel: +1 (857) 352-6286" : "tel: 0810 345 0562"}
          style={{ width: isMoreThanOne ? "100%" : "max-content" }}
        >
          <FontAwesomeIcon icon={faPhoneAlt} />
          {!isInEnglish ? "Llamanos ya" : "Call us now"}
        </Button>
      </Col>
    </>
  );
};

export default GetInTouchPersonal;
