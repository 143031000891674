export const bannerText = {
  title: "Work with top development teams",
  subTitle:
    "We focus on delivering powerful custom software engineering solutions to help your business scale to the next level. With agile methodologies, we develop under the best dynamic and reliable practices.",
  buttonConsult: "Get Free Consultation",
  ourServices: "Our services",
  certificatePart1: "Our processes are accredited by ISO 9001:2015",
  certificatePart2:
    "certification in quality practices for the development of software products.",
};

export const trusted = {
  title: "Trusted by",
};
export const menuServices = {
  title: "Services",
  subtitle1: "Get your business up and running with",
  subtitle2: "our services",
  softPart1: "Software",
  softPart2: "Engineering",
  button: "Learn More",
  sre: "Cloud Engineering & DevOps Practice",
  staffPart1: "Staff",
  staffPart2: "Augmentation",
  staffPart1MenuHeader: "Staff augmentation",
};
export const aboutUsWhatMovesUs = {
  aboutMenuHeader: "About",
  titleLinkMenu: "About",
  title: "About us",
  subTitle: "What moves us",
  description1: `We help companies transform their ideas into effective software products and design, operate, and scale their digital ecosystems by using best practices in software development, platform engineering, and digital transformation, ensuring they innovate, remain competitive, and stay relevant in the market.`,
  whatWeAre: "What we are",
};
export const valueProposal = {
  title: "Value proposal",
  description1: `We provide services that address this critical gap, enabling non-tech-savvy companies to confidently navigate the complexity of the technological and digital landscape, innovate without limitations, and remain competitive, ensuring relevance and success in a market that does not forgive those who fail to adapt.`,
  description2: `We co-create high-quality software products with our clients, addressing digital transformation and always emphasizing design and usability.`,
  button: "More about us",
};

export const organizationalPolicy = {
  title: "Organizational policy",
};
export const careers = {
  title: "Careers",
  work: "Work with us!",
  apply: "Apply now",
};
export const contact = {
  title: "Contact",
  contactLink: "Contact us",
};
export const downloadFree = {
  title: "Download our deck and let’s talk",
};
export const page404 = {
  title: "Something gone missing...",
  paragrahp:
    "Sorry, the content you are looking for does not exist. Either it has been removed, or you mistyped the link.",
  button: "Go Home",
};
